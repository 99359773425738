import { useAuth } from "oidc-react";
import { useMemo } from "react";
import { AvailableScoreExportPerGroup } from "./ExportSelection";

export function useAvailableExport(): AvailableScoreExportPerGroup[] {
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;


  return useMemo(() => {
    if (userId === "635e358a-397d-4266-87cc-a3e9ae97a6f7" || userId === "bdce6822-b68b-4f72-98a7-0086695d4155") {
      return [
        {
          groupName: "PRORAD_ZUERICH",
          scores: [
            {
              scoreName: "ProRaD",
              groupName: "PRORAD_ZUERICH",
            },
            {
              scoreName: "PO-SCORAD",
              groupName: "PRORAD_ZUERICH",
            },
            {
              scoreName: "DLQI",
              groupName: "PRORAD_ZUERICH",
            }
          ]
        }
      ];
    } else if(userId === "e925ae38-b76b-4eda-92f9-31673f180973" || userId === "80857e8c-0b1c-4e39-9b16-afbe1e09f5a5") {
      return [
        {
          groupName: "PRORAD_AUGSBURG",
          scores: [
            {
              scoreName: "ProRaD",
              groupName: "PRORAD_AUGSBURG",
            },
            {
              scoreName: "PO-SCORAD",
              groupName: "PRORAD_AUGSBURG",
            },
            {
              scoreName: "DLQI",
              groupName: "PRORAD_AUGSBURG",
            }
          ]
        },
        {
          groupName: "PRORAD_DAVOS",
          scores: [
            {
              scoreName: "ProRaD",
              groupName: "PRORAD_DAVOS",
            },
            {
              scoreName: "PO-SCORAD",
              groupName: "PRORAD_DAVOS",
            },
            {
              scoreName: "DLQI",
              groupName: "PRORAD_DAVOS",
            }
          ]
        },
        {
          groupName: "PRORAD_ZUERICH",
          scores: [
            {
              scoreName: "ProRaD",
              groupName: "PRORAD_ZUERICH",
            },
            {
              scoreName: "PO-SCORAD",
              groupName: "PRORAD_ZUERICH",
            },
            {
              scoreName: "DLQI",
              groupName: "PRORAD_ZUERICH",
            }
          ]
        }
      ];
    }
    return [];
  }, [userId]);
}