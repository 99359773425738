import { ChangeEvent, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
const SCORE_NAMES = ["DSAS", "ProRaD", "PO-SCORAD", "Flare-up", "DLQI"] as const;
export type Score = (typeof SCORE_NAMES)[number];

export type ScoreExport = {
  groupName: string;
  scoreName: Score;
}

export type AvailableScoreExportPerGroup = {
  groupName: string;
  scores: ScoreExport[];
};

type GroupSelectionProps = {
  availableValues: AvailableScoreExportPerGroup[];
  value : AvailableScoreExportPerGroup;
  onChange : (value : AvailableScoreExportPerGroup) => void;
};

const GroupSelection : React.FC<GroupSelectionProps> = ({availableValues, value, onChange}) => {
  const options = useMemo(() => availableValues.map(item => (
      <option key={item.groupName} value={item.groupName}>
        {item.groupName}
      </option>
    )),
    [availableValues]
  );
  
  const handleChange = useCallback((event : ChangeEvent<HTMLSelectElement> )  => {
    const availableScoresForGroup = availableValues.find((item) => item.groupName === event.target.value);
    if(availableScoresForGroup) {
      onChange(availableScoresForGroup);
    } else {
      console.log(`No scores available for group ${event.target.value}`);
    }
  }, [availableValues, onChange]);

  return (
    <Form.Select value={value.groupName} onChange={handleChange} >
      {options}
    </Form.Select>
  )
}

type ScoreSelectionProps = {
  availableValues: ScoreExport[];
  value : ScoreExport;
  onChange : (value : ScoreExport) => void;
};

const ScoreSelection : React.FC<ScoreSelectionProps> = ({availableValues, value, onChange}) => {
  const options = useMemo(() => availableValues.map(item => (
      <option key={item.scoreName} value={item.scoreName}>
        {item.scoreName}
      </option>
    )),
    [availableValues]
  );
  
  const handleChange = useCallback((event : ChangeEvent<HTMLSelectElement> )  => {
    const scoreExport = availableValues.find(item => item.scoreName === event.target.value);
    if(scoreExport) {
      onChange(scoreExport);
    } else {
      console.log(`No export available for score ${event.target.value}`);
    }
  }, [availableValues, onChange]);

  return (
    <Form.Select value={value.scoreName} onChange={handleChange} >
      {options}
    </Form.Select>
  )
}

type ExportSelectionProps = {
  availableScoreExports : AvailableScoreExportPerGroup[];
  selectedScoreExport : ScoreExport;
  onChange : (scoreExport : ScoreExport) => void;
};

export const ExportSelection : React.FC<ExportSelectionProps> = ({availableScoreExports, selectedScoreExport, onChange}) => {
  const { t } = useTranslation();
  
  const selectedScoreExportForGroup = useMemo(() => {
      const selected = availableScoreExports.find(item => item.groupName === selectedScoreExport.groupName);
      if(selected) {
        return selected;
      } else if(availableScoreExports.length > 0) {
        return availableScoreExports[0];
      } else {
        console.log("No scores for group available");
      }
    },
    [availableScoreExports, selectedScoreExport.groupName]
  );
  
  const handleGroupChange = useCallback((item: AvailableScoreExportPerGroup) => {
    const score = item.scores.find((score) => score.scoreName === selectedScoreExport.scoreName);
    if(score) {
      onChange(score)
    } else if(item.scores.length > 0) {
      onChange(item.scores[0]);
    } else {
      console.log("No scores for group available");
    }
  }, [onChange, selectedScoreExport.scoreName]);

  if(selectedScoreExportForGroup) {
    return(
      <>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <GroupSelection availableValues={availableScoreExports} value={selectedScoreExportForGroup} onChange={handleGroupChange} />
        </Form.Group>
        <Form.Group style={{ marginTop: "0.5rem" }}>
          <ScoreSelection availableValues={selectedScoreExportForGroup.scores} value={selectedScoreExport} onChange={onChange} />
        </Form.Group>
      </>
    );
  } 

  return (
    <p>{t("export.noDataAvailable")}</p>
  );
};
