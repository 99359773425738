import { useAuth, User } from "oidc-react";
import styles from "./Export.module.css";
import { FormEvent, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";

import { useImmer } from "use-immer";
import {
  createDlqiResource,
  createPoscoradResource,
  createProradResource,
} from "../client/backend/BackendClientFactory";
import { saveData } from "../download/DownloadUtil";
import { format } from "date-fns";
import { useAvailableExport } from "./useAvailableExport.hook";
import { ExportSelection, ScoreExport } from "./ExportSelection";

const DAY_FORMAT = "yyyy-MM-dd";



async function exportScoreAsync(exportScore: ScoreExport, user : User) : Promise<void> {
  const {groupName, scoreName} = exportScore;
  const response = await getCsvData(groupName, scoreName, user);

  saveData(
    response,
    `${format(new Date(), DAY_FORMAT)}-${scoreName}-${groupName}.csv`
  );
}

async function getCsvData(groupName : string, scoreName : string, user : User) : Promise<string> {
  switch(scoreName) {
    case "ProRaD": 
      return createProradResource(user).getProradsForGroupAsCsv({ groupName });
    case "DLQI": 
      return createDlqiResource(user).getDlqisForGroupAsCsv({ groupName });
    case "PO-SCORAD":
      return createPoscoradResource(user).getPoscoradsForGroupAsCsv({ groupName });
  }
  throw new Error(`Unsupported score: ${scoreName}`);
}

const Content : React.FC = () => {
  const { t } = useTranslation();
  const availableExports = useAvailableExport();
  const auth = useAuth();

  const [selectedExportScore, setSelectedExportScore] = useImmer<ScoreExport | undefined>(
    undefined
  );

  useEffect(() => setSelectedExportScore(availableExports[0].scores[0]),[availableExports, setSelectedExportScore]);

  const handleSubmit = useCallback((e : FormEvent) => {
    e.preventDefault();

    if(selectedExportScore && auth.userData) {
      exportScoreAsync(selectedExportScore, auth.userData);
    }
  },[auth.userData, selectedExportScore]);

  if(selectedExportScore) {
    return (
      <Form onSubmit={handleSubmit} style={{ marginTop: "2rem" }}>
        <ExportSelection availableScoreExports={availableExports} selectedScoreExport={selectedExportScore} onChange={setSelectedExportScore} />
        <Button style={{ marginTop: "0.5rem" }} type="submit">
          {t("export.export")}
        </Button>
      </Form>
    );  
  }
  return (
    <p>{t("export.noExportsAvailable")}</p>
  )
}


export const Export : React.FC = () => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.export}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    >
      <div style={{ width: "50%" }}>
        <Card style={{ backgroundColor: "#f3f6ff", padding: "1rem" }}>
          <Card.Text style={{ textAlign: "center" }}>
            {t("export.hint")}
          </Card.Text>
        </Card>
        <Content />
      </div>
    </div>
  );
};
