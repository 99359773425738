import styles from "./Report.module.css";

export const Report : React.FC = () => {
  return (
    <div
      className={styles.report}
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: "5rem",
      }}
    ></div>
  );
};
